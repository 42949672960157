<template>
    <div class="col-span-2">
        <div class="text-black text-base xl:text-lg font-bold font-display mb-4">
            Top Rated Indicators
        </div>
        <div class="flex items-center justify-between p-2 border-b">
            <div class="font-display text-green font-bold">
                Most Met
            </div>
            <div
                class="text-right text-grey-1 font-bold"
                v-if="stats.topRatedIndicators.met.count > 0"
            >
                {{ stats.topRatedIndicators.met.indicatorCode }}
                <v-tooltip right>
                    <template v-slot:activator="{on, attrs}">
                        <fa-icon
                            v-bind="attrs"
                            v-on="on"
                            icon="info-circle"
                            class="ml-1 text-sm text-grey-2"
                        ></fa-icon>
                    </template>
                    <span>{{ stats.topRatedIndicators.met.indicatorText }}</span>
                </v-tooltip>
            </div>
        </div>
        <div class="flex items-center justify-between p-2 border-b">
            <div class="font-display text-red font-bold">
                Most Not Met
            </div>
            <div
                class="text-right text-grey-1 font-bold"
                v-if="stats.topRatedIndicators.notMet.count > 0"
            >
                {{ stats.topRatedIndicators.notMet.indicatorCode }}
                <v-tooltip right>
                    <template v-slot:activator="{on, attrs}">
                        <fa-icon
                            v-bind="attrs"
                            v-on="on"
                            icon="info-circle"
                            class="ml-1 text-sm text-grey-2"
                        ></fa-icon>
                    </template>
                    <span>{{ stats.topRatedIndicators.notMet.indicatorText }}</span>
                </v-tooltip>
            </div>
        </div>
        <div class="flex items-center justify-between p-2 border-b">
            <div class="font-display text-orange font-bold">
                Most N/A
            </div>
            <div
                class="text-right text-grey-1 font-bold"
                v-if="stats.topRatedIndicators.notApplicable.count > 0"
            >
                {{ stats.topRatedIndicators.notApplicable.indicatorCode }}
                <v-tooltip right>
                    <template v-slot:activator="{on, attrs}">
                        <fa-icon
                            v-bind="attrs"
                            v-on="on"
                            icon="info-circle"
                            class="ml-1 text-sm text-grey-2"
                        ></fa-icon>
                    </template>
                    <span>
                        {{stats.topRatedIndicators.notApplicable.indicatorText}}
                    </span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopRatedIndicators',
    props: {
        stats: {
            type: Object,
            default: () => {
            }
        }
    }
}
</script>

