import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-span-2"},[_c('div',{staticClass:"text-black text-base xl:text-lg font-bold font-display mb-4"},[_vm._v(" Top Rated Indicators ")]),_c('div',{staticClass:"flex items-center justify-between p-2 border-b"},[_c('div',{staticClass:"font-display text-green font-bold"},[_vm._v(" Most Met ")]),(_vm.stats.topRatedIndicators.met.count > 0)?_c('div',{staticClass:"text-right text-grey-1 font-bold"},[_vm._v(" "+_vm._s(_vm.stats.topRatedIndicators.met.indicatorCode)+" "),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('fa-icon',_vm._g(_vm._b({staticClass:"ml-1 text-sm text-grey-2",attrs:{"icon":"info-circle"}},'fa-icon',attrs,false),on))]}}],null,false,3832385780)},[_c('span',[_vm._v(_vm._s(_vm.stats.topRatedIndicators.met.indicatorText))])])],1):_vm._e()]),_c('div',{staticClass:"flex items-center justify-between p-2 border-b"},[_c('div',{staticClass:"font-display text-red font-bold"},[_vm._v(" Most Not Met ")]),(_vm.stats.topRatedIndicators.notMet.count > 0)?_c('div',{staticClass:"text-right text-grey-1 font-bold"},[_vm._v(" "+_vm._s(_vm.stats.topRatedIndicators.notMet.indicatorCode)+" "),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('fa-icon',_vm._g(_vm._b({staticClass:"ml-1 text-sm text-grey-2",attrs:{"icon":"info-circle"}},'fa-icon',attrs,false),on))]}}],null,false,3832385780)},[_c('span',[_vm._v(_vm._s(_vm.stats.topRatedIndicators.notMet.indicatorText))])])],1):_vm._e()]),_c('div',{staticClass:"flex items-center justify-between p-2 border-b"},[_c('div',{staticClass:"font-display text-orange font-bold"},[_vm._v(" Most N/A ")]),(_vm.stats.topRatedIndicators.notApplicable.count > 0)?_c('div',{staticClass:"text-right text-grey-1 font-bold"},[_vm._v(" "+_vm._s(_vm.stats.topRatedIndicators.notApplicable.indicatorCode)+" "),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('fa-icon',_vm._g(_vm._b({staticClass:"ml-1 text-sm text-grey-2",attrs:{"icon":"info-circle"}},'fa-icon',attrs,false),on))]}}],null,false,3832385780)},[_c('span',[_vm._v(" "+_vm._s(_vm.stats.topRatedIndicators.notApplicable.indicatorText)+" ")])])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }