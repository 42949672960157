<template>
    <div class="">
        <div class="container py-4">
            <div class="flex flex-col w-full">
                <v-alert
                    v-if="showAlert"
                    :closable="false"
                    type="error"
                    class="text-lg"
                >{{ message }}</v-alert>
            </div>
            <div class="flex items-center justify-between my-4">
                <h1 class="text-black text-lg lg:text-2xl">Comparative Report</h1>
                <v-menu offset-y>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                            depressed
                            class="hidden font-bold text-sm"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Download CSV
                            <fa-icon
                                icon="chevron-down"
                                class="text-xs text-white-pure ml-1"
                            ></fa-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <a
                                    href="#"
                                    @click.prevent="downloadIndicatorComplianceReportHandler"
                                >Indicator Compliance Report</a>
                            </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item>
                            <v-list-item-title>
                                Due Dates Report
                            </v-list-item-title>
                        </v-list-item> -->
                    </v-list>
                </v-menu>
            </div>
            <div class="hidden inline-flex">
                <div class="fa-container mt-5 mr-10">
                    <fa-icon icon="filter" class="text-black" />
                </div>
                <date-range-picker
                    v-model="dateRange"
                    class="mr-4"
                ></date-range-picker>
                <v-select
                    :items="visitTypes"
                    v-model="visitTypeFilter"
                    label="Visit Types"
                    class="mr-4"
                ></v-select>
                <v-select
                    :items="practiceTypes"
                    v-model="practiceTypeFilter"
                    label="Practice Types"
                    class="mr-4"
                ></v-select>
            </div>
            <div class="grid grid-cols-5 gap-4 mt-6">
                <div class="col-span-2 grid grid-cols-2 gap-4">
                    <ReportSummary :stats="stats" />
                    <TopRatedIndicators :stats="stats" />
                </div>

                <div class="col-span-3 bg-grey-7 p-4 rounded">
                    <div class="text-center text-black text-base xl:text-xl font-bold font-display">
                        Average Survey Results
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div class="col-span-1 text-center">
                            <span
                                class="text-grey-2 uppercase font-bold text-xs font-display"
                            >My practices</span>
                            <div ref="myAvgPerCompliance"></div>
                        </div>
                        <div class="col-span-1 text-center">
                            <span
                                class="text-grey-2 uppercase font-bold text-xs font-display"
                            >All practices in Connect</span>
                            <div ref="allAvgPerCompliance"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-5 gap-4 mt-8">
                <Top5Practices :stats="stats" />
                <Lowest5Practices :stats="stats" />
                <div class="col-span-3 bg-grey-7 p-4 rounded">
                    <div
                        class="text-center text-black text-base xl:text-xl font-bold font-display mb-4"
                    >
                        Practices Above & Below Averages
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="col-span-1 text-center">
                            <span
                                class="text-grey-2 uppercase font-bold text-xs font-display"
                            >My practices avg.</span>
                            <div ref="myAboveBelowAvg"></div>
                        </div>
                        <div class="col-span-1 text-center">
                            <span
                                class="text-grey-2 uppercase font-bold text-xs font-display"
                            >All Connect practices avg.</span>
                            <div ref="allAboveBelowAvg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import camelcaseKeys from 'camelcase-keys'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {Chart} from 'frappe-charts/dist/frappe-charts.min.esm'
import DateRangePicker from '@/components/partials/DateRangePicker'
import { AuthMixin } from "@/components/shared/mixins/authHelpers";
import ReportSummary from '@/components/reports/partials/ReportSummary'
import TopRatedIndicators from '@/components/reports/partials/TopRatedIndicators'
import Top5Practices from '@/components/reports/partials/Top5Practices'
import Lowest5Practices from '@/components/reports/partials/Lowest5Practices'
import { DEFAULT_ERROR_REPORT_TEXT } from '@/config/constants'

export default {
    name: 'ReportsView',
    components: { Top5Practices, TopRatedIndicators, ReportSummary, DateRangePicker, Lowest5Practices },
    mixins: [FileMethods, MessageDialog, AuthMixin],
    data: () => ({
        loading: false,
        showAlert: false,
        message: '',
        stats: {
            totalPractices: 0,
            openCycles: 0,
            selfAssessmentDue: 0,
            actionPlan: 0,
            topRatedIndicators: {
                met: {
                    count: 0,
                    indicatorCode: '',
                    indicatorId: '',
                    indicatorText: ''
                },
                notMet: {
                    count: 0,
                    indicatorCode: '',
                    indicatorId: '',
                    indicatorText: ''
                },
                notApplicable: {
                    count: 0,
                    indicatorCode: '',
                    indicatorId: '',
                    indicatorText: ''
                }
            },
            top5Practices: [],
            bottom5Practices: [],
            myAvgPerCompliance: {
                met: 0,
                notMet: 0,
                notApplicable: 0
            },
            allAvgPerCompliance: {
                met: 0,
                notMet: 0,
                notApplicable: 0
            },
            myAboveBelowAvg: {
                above: 0,
                below: 0
            },
            allAboveBelowAvg: {
                above: 0,
                below: 0
            }
        },
        chartSize: 280,
        dateRange: [],
        visitTypes: [],
        practiceTypes: [],
        visitTypeFilter: '',
        practiceTypeFilter: '',
    }),
    mounted() {
        if (!this.isPracticeManager && !this.isAdmin) this.$router.push({ path: '/Dashboard' })
        this.loadStats()
        this.practiceTypes = this.staticChoices['practice.practice_type'] || []
        this.practiceTypes.unshift('All')
        this.visitTypes = this.staticChoices['survey_visit.type'] || []
        this.visitTypes.unshift('All')
    },
    methods: {
        loadStats() {
            this.loading = true
            this.showAlert = false
            this.message = ''

            this.$http
                .get(`/reports/comparative/metrics/${this.metricFilter}`)
                .then(({data}) => {
                    this.stats = camelcaseKeys(data, {deep: true})

                    this.drawAvgSurveyResultChart()
                    this.drawPractiveAboveBelowChart()
                })
                .catch(({ response }) => {
                    this.showAlert = true
                    this.message = response?.status === 403 ? response?.data?.error || DEFAULT_ERROR_REPORT_TEXT : DEFAULT_ERROR_REPORT_TEXT
                })
                .finally(() => {
                    this.loading = false
                })
        },
        downloadIndicatorComplianceReportHandler() {
            this.$http({
                url: '/reports/comparative/indicator_compliance_csv/',
                method: 'GET',
                responseType: 'blob'
            })
                .then(res => {
                    this.downloadFile(res.data, res.headers['content-disposition'].split('=')[1])
                    this.type = 'success'
                    this.message = 'Successfully downloaded the Indicator Compliance Report.'
                })
                .catch(err => {
                    console.log('Indicator Compliance Report download error', err)
                    this.type = 'error'
                    this.message =
                        'Sorry, your file could not be downloaded at this time, please contact your system administrator'
                })
                .finally(() => {
                    this.showMessage({duration: 5000})
                })
        },
        drawAvgSurveyResultChart() {
            new Chart(this.$refs.myAvgPerCompliance, {
                data: {
                    labels: [''],
                    datasets: [
                        {
                            name: 'Met',
                            values: [this.round(this.stats.myAvgPerCompliance.met)]
                        },
                        {
                            name: 'Not Met',
                            values: [this.round(this.stats.myAvgPerCompliance.notMet)]
                        },
                        {
                            name: 'N/A',
                            values: [this.round(this.stats.myAvgPerCompliance.notApplicable)]
                        }
                    ]
                },
                type: 'bar',
                height: this.chartSize,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00' // tailwind: risk-matrix-orange-dark
                ],
                tooltipOptions: {
                  formatTooltipY: d => d + '%',
                }
            })

            new Chart(this.$refs.allAvgPerCompliance, {
                data: {
                    labels: [''],
                    datasets: [
                        {
                            name: 'Met',
                            values: [this.round(this.stats.allAvgPerCompliance.met)]
                        },
                        {
                            name: 'Not Met',
                            values: [this.round(this.stats.allAvgPerCompliance.notMet)]
                        },
                        {
                            name: 'N/A',
                            values: [this.round(this.stats.allAvgPerCompliance.notApplicable)]
                        }
                    ]
                },
                type: 'bar',
                height: this.chartSize,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00' // tailwind: risk-matrix-orange-dark
                ],
              tooltipOptions: {
                formatTooltipY: d => d + '%',
              }
            })
        },
        drawPractiveAboveBelowChart() {
            new Chart(this.$refs.myAboveBelowAvg, {
                data: {
                    labels: ['Above', 'Below'],
                    datasets: [
                        {
                            values: [
                                this.round(this.stats.myAboveBelowAvg.above),
                                this.round(this.stats.myAboveBelowAvg.below)
                            ]
                        }
                    ]
                },
                type: 'donut',
                height: this.chartSize,
                truncateLegends: true,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00' // tailwind: risk-matrix-orange-dark
                ]
            })

            new Chart(this.$refs.allAboveBelowAvg, {
                data: {
                    labels: ['Above', 'Below'],
                    datasets: [
                        {
                            values: [
                                this.round(this.stats.allAboveBelowAvg.above),
                                this.round(this.stats.allAboveBelowAvg.below)
                            ]
                        }
                    ]
                },
                type: 'donut',
                height: this.chartSize,
                truncateLegends: true,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00' // tailwind: risk-matrix-orange-dark
                ]
            })
        },
        round(val) {
            return Math.round(val * 100) / 100
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        metricFilter() {
            const dateRange = this.dateRange[0] && this.dateRange[1] ? `${this.dateRange[0]},${this.dateRange[1]}` : ''
            const visitType = !this.visitTypeFilter || this.visitTypeFilter === 'All' ? '' : this.visitTypeFilter
            const practiceType = !this.practiceTypeFilter || this.practiceTypeFilter === 'All' ? '' : this.practiceTypeFilter
            return `?dateRange=${dateRange}&visitType=${visitType}&practiceType=${practiceType}`
        }
    },
     watch: {
        metricFilter() {
            this.loadStats()
        }
    }
}
</script>
