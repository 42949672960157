<template>
    <div class="col-span-2 grid grid-cols-2 gap-4">
        <router-link
            :to="{name: 'PracticeList'}"
            class="col-span-1 bg-grey-7 p-4 rounded cursor-pointer flex items-center justify-between"
        >
            <div class="flex items-center">
                <span
                    class="font-bold text-lg text-black mr-1"
                >{{ stats.totalPractices }}</span>
                <span
                    class="text-grey-2 uppercase font-bold text-xs font-display"
                >Total Practices</span>
            </div>
            <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
        </router-link>
        <router-link
            :to="{name: 'PracticeList', query: {cycleOpen: true}}"
            class="col-span-1 bg-grey-7 p-4 rounded cursor-pointer flex items-center justify-between"
        >
            <div class="flex items-center">
                <span
                    class="font-bold text-lg text-black mr-1"
                >{{ stats.openCycles }}</span>
                <span
                    class="text-grey-2 uppercase font-bold text-xs font-display"
                >in open cycles</span>
            </div>
            <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
        </router-link>
        <router-link
            :to="{name: 'PracticeList', query: {selfAssessmentDue: true}}"
            class="col-span-1 bg-grey-7 p-4 rounded cursor-pointer flex items-center justify-between"
        >
            <div class="flex items-center">
                <span
                    class="font-bold text-lg text-black mr-1"
                >{{ stats.selfAssessmentDue }}</span>
                <span
                    class="text-grey-2 uppercase font-bold text-xs font-display"
                >Self assessment due</span>
            </div>
            <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
        </router-link>
        <router-link
            :to="{name: 'PracticeList', query: {actionRequired: true}}"
            class="col-span-1 bg-grey-7 p-4 rounded cursor-pointer flex items-center justify-between"
        >
            <div class="flex items-center">
                <span
                    class="font-bold text-lg text-black mr-1"
                >{{ stats.actionPlan }}</span>
                <span
                    class="text-grey-2 uppercase font-bold text-xs font-display"
                >Practices require action</span>
            </div>
            <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'report-summary',
    props: {
        stats: {
            type: Object,
            default: () => {
            }
        }
    }
}
</script>
