<template>
    <div class="col-span-1">
        <div class="text-black text-base xl:text-lg font-bold font-display mb-4">
            Top 5 Practices
        </div>
        <router-link
            v-for="practice in stats.top5Practices"
            :key="`top-practice-${practice.id}`"
            :to="{name: 'Accreditation', params: {id: practice.id}}"
            class="flex text-sm items-center border-b justify-between p-2 cursor-pointer hover:bg-grey-8"
        >
            <div class="truncate">
                {{ practice.name }}
            </div>
            <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'Top5Practices',
    props: {
        stats: {
            type: Object,
            default: () => {}
        }
    }
}
</script>
